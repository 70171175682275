.cardLogoChannel {
  @mixin size 100%;
  position: absolute;
  z-index: 3;
  overflow: hidden;
  border-radius: var(--border-radius-small);

  &--borderRadius {
    border-radius: var(--border-radius-big);
  }

  &--disabled {
    cursor: default;
  }

  @media (--mq-from-tablet) {
    &::before {
      position: absolute;
      bottom: -20px;
      left: -20px;
      display: block;
      width: 2px;
      height: 2px;
      content: '';
      background: var(--color_black);
      border-radius: 100%;
      box-shadow: 0px 0px 80px 80px rgba(0, 0, 0, 0.3);
    }
  }

  &--shadow {
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0) 40%
    );
  }

  &--gradient {
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      content: '';
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0) 23%,
        rgba(0, 0, 0, 0) 1%
      );
    }

    &-livetvCard {
      &::after {
        background-image: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0) 0%
        );
      }
    }
  }

  &__channel {
    @mixin size 3rem, 2.25rem;
    position: absolute;
    bottom: 0;
    left: 0.1875rem;
    max-width: 3rem;

    @media (--mq-from-tablet) {
      @mixin size auto;
    }

    &--largeLogo {
      @mixin size 6rem, 4.5rem;
      max-width: 6rem;
    }

    &--extraLargeLogo {
      @mixin size 5.1875rem, auto;
      max-width: 40%;
    }

    &--isProgressBarDisplayed {
      margin-bottom: 0.5rem;
    }

    @mixin tvDevice {
      @mixin size 5.625rem, 4.25rem;
      max-width: 5.625rem;
    }
  }

  &__content {
    position: absolute;
    right: 0;
    bottom: 0.25rem;
    left: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 1.6875rem;
    padding: 0 0.375rem;

    @mixin tvDevice {
      left: 5.625rem;
      height: 3.75rem;
    }

    &--noLogo {
      left: 0;
      padding-left: 0.5rem;

      @mixin tvDevice {
        left: 0;
      }
    }

    &--isLarge {
      left: 6.25rem;
      width: calc(100% - 6.5625rem);
      height: 3.75rem;
      padding-left: 0;

      @mixin tvDevice {
        left: 6.25rem;
        height: 3.75rem;
      }
    }

    &--isProgressBarDisplayed {
      margin-bottom: 0.5rem;
    }
  }
}
