.ProgressBar {
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 0.25rem;
  overflow: hidden; /* so we don't have to round the corners of the progressLine */
  background-color: var(--color_grey-350-low-opacity);
  border-radius: var(--border-radius-small);

  @mixin tvDevice {
    flex-grow: 1;
    width: auto;
    height: 0.375rem;
    border-radius: var(--border-radius-extraLarge);
  }

  &__progressLine {
    height: 100%;
    /* width calculated by component */
    background-color: var(--color_cardProgressBar-bg);
    &-onGoing {
      background-color: var(--color_white);
    }
    &-liveShow {
      background-color: var(--color_cardProgressBar-bg);
    }
  }

  &__check {
    @mixin size 1.375rem;
    position: absolute;
    right: 0.625rem;
    bottom: 0.625rem;
    z-index: 2;
    background-color: var(--color_white);
    border-radius: 50%;

    @mixin tvDevice {
      @mixin size 2.5rem;
    }
  }

  &__icon {
    fill: var(--color_secondary);

    [data-theme~='telecomitalia'] & {
      fill: var(--color_black);
    }
  }
}
