.HorizontalListVirtual {
  --list-side-padding: var(--HZL-list-side-padding, 0);
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  @mixin hideScrollbar;

  &__list {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0.5rem var(--list-side-padding);
    margin: 0;
    white-space: nowrap;

    & > li {
      display: inline-block;
    }
  }
}
