:root {
  --marginItem-for-large-desktop: 0.3125rem !important;
  --marginItem-for-tablet: 0.3125rem !important;
  --marginItem-for-tv: 0.25rem 1.625rem !important;
}

.carrouselTemplate {
  &__cover--166 > :first-child {
    @mixin aspectRatioLegacy 16, 6, -1px;
  }
  &__cover--169 > :first-child {
    @mixin aspectRatioLegacy 16, 9, -1px;
  }
  &__cover--43 > :first-child {
    @mixin aspectRatioLegacy 4, 3, -1px;
  }
  &__progressBarPositioner {
    position: absolute;
    top: auto;
    bottom: 0.25rem;
    z-index: 2;
    width: 100%;
    padding: 0 0.3125rem;
  }

  &__cover {
    @media (--mq-from-tablet) {
      @mixin marginGridItems var(--marginItem-for-large-desktop),
        var(--marginItem-for-tablet), var(--marginItem-for-tablet);
    }

    @mixin tvDevice {
      @mixin paddingPages var(--marginItem-for-tv), var(--marginItem-for-tv),
        var(--marginItem-for-tv), var(--marginItem-for-tv);
    }
  }

  &__sticker {
    --sticker-margin: 0.375rem;

    @mixin tvDevice {
      --sticker-margin: 0.5rem;
    }

    position: absolute;
    top: var(--sticker-margin);
    right: var(--sticker-margin);
    left: auto;
    z-index: 2;
    max-width: calc(100% - calc(var(--sticker-margin) * 2));
  }
}

/* TV focus style */
:global {
  .carrousel .tv__focus::before {
    @mixin tv-focus-pseudo-border;
  }
}
