:root {
  --marginItem-for-large-desktop: 0.625rem;
  --marginItem-for-tablet: 0.3125rem;
  --marginItem-for-small: 0.1875rem;
  --margin-for-laptop: 0.625rem 1.875rem;
  --margin-for-desktop: 0.625rem 3.75rem;
  --margin-for-small: 0.75rem 0.75rem 0.875rem;
}

.CarouselVirtual {
  @mixin size 100%, auto;
  @mixin hideScrollbar;
  position: relative;
  z-index: 1;
  overflow: hidden;
  font-size: 0; /* fix space between inline--block elements */
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  &__item {
    position: relative;
    display: inline-block;
    width: 100%;

    @mixin responsiveGrid carrouselTv, true;

    @media (--mq-from-tablet) {
      @mixin responsiveGrid carrousel;
      vertical-align: top;

      &::before {
        @mixin marginGridItems var(--marginItem-for-large-desktop),
          var(--marginItem-for-tablet), var(--marginItem-for-small);
        display: block;
      }
    }
  }

  &__tablet {
    display: none;

    @media (--mq-from-tablet) {
      display: block;
    }
  }

  &--cover {
    .carousel__track {
      margin: 0;
    }
  }

  --HZL-list-side-padding: 4.0625rem;
}
